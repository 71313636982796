let shadowRoot;
let propertyOwnership;

let propertyType;
let propertyTypeValue;

let propertyBedrooms;
let propertyBedroomsValue;
let propertyFloorAreaValue;

let propertyAge;
let propertyAgeValue;

let propertyUpgrade;
let propertyUpgradeValue;

let propertyPipe;
let propertyPipeValue;

window.addEventListener('heyflow-init', (event) => {
    shadowRoot = 
        (document.querySelector('heyflow-wrapper') &&
            document.querySelector('heyflow-wrapper').shadowRoot) ||
        document;
        
        console.log(shadowRoot);
});


window.addEventListener('heyflow-change', (event) => {
    
    let screenName = event.detail.screenName
    
    
    
    
    console.log(screenName);
    
    if(screenName == "heatloss-calc-property-ownership"){
        propertyOwnership = event.detail.fieldsSimple['heatloss-calc-property-ownership'];
        console.log(propertyOwnership);
    }
    else if(screenName == "heatloss-calc-property-type"){
        propertyType = event.detail.fieldsSimple['heatloss-calc-property-type'];
        console.log(`Property Type: ${propertyType}`);

        switch (propertyType) {
        
            case "Detached":
                propertyTypeValue = 1;
                break;
        
            case "Semi-Detached":
                propertyTypeValue = 0.97;
                break;
        
            case "End Terrace":
                propertyTypeValue = 0.97;
                break; 
        
            case "Mid Terrace":
                propertyTypeValue = 0.95;
                break;
        
            case "Bungalow":
                propertyTypeValue = 1.1;
                break;

            case "Flat":
                propertyTypeValue = 0.9;
                break;
          
              default:
                propertyTypeValue = 0;
        }

        console.log(`Property Type Value: ${propertyTypeValue}`);
    }
    else if(screenName == "heatloss-calc-property-bedrooms"){
        propertyBedrooms = event.detail.fieldsSimple['heatloss-calc-property-bedrooms'];
        console.log(`Property Bedroom: ${propertyBedrooms}`);

        switch (propertyBedrooms) {
        
            case "1 bedroom":
                propertyBedroomsValue = 1;
                propertyFloorAreaValue = 20;
                break;
        
            case "2 bedrooms":
                propertyBedroomsValue = 2;
                propertyFloorAreaValue = 40;
                break;
        
            case "3 bedrooms":
                propertyBedroomsValue = 3;
                propertyFloorAreaValue = 65;
                break; 
        
            case "4 bedrooms":
                propertyBedroomsValue = 4;
                propertyFloorAreaValue = 85;
                break;
        
            case "5+ bedrooms":
                propertyBedroomsValue = 5;
                propertyFloorAreaValue = 120;
                break;

              default:
                propertyBedroomsValue = 0;
                propertyFloorAreaValue = 0;
        }

        console.log(`Property Floor Area Value: ${propertyFloorAreaValue}`);
        console.log(`Property Bedroom Value: ${propertyBedroomsValue}`);
    }
    else if(screenName == "heatloss-calc-property-age"){
        propertyAge = event.detail.fieldsSimple['heatloss-calc-property-age'];
        console.log(`Property Age: ${propertyAge}`);

        switch (propertyAge) {
        
            case "Pre 1920":
                propertyAgeValue = 1.8;
                break;
        
            case "1921 - 1960":
                propertyAgeValue = 1.6;
                break;
        
            case "1961 - 1990":
                propertyAgeValue = 1.4;
                break; 
        
            case "1991 - 2010":
                propertyAgeValue = 1.3;
                break;
        
            case "2011 - 2023":
                propertyAgeValue = 1;
                break;

            case "Under Construction":
                propertyAgeValue = 0.8;
                break;

              default:
                propertyAgeValue = 0;
        }

        console.log(`Property Age Value: ${propertyAgeValue}`);
    }
    else if(screenName == "heatloss-calc-property-upgraded"){
        propertyUpgrade = event.detail.fieldsSimple['heatloss-calc-property-upgraded'];
        console.log(`Property Upgraded: ${propertyUpgrade}`);

        switch (propertyUpgrade) {
        
            case "Yes":
                propertyUpgradeValue = 0.95;
                break;
        
            case "No":
                propertyUpgradeValue = 1;
                break;

              default:
                propertyUpgradeValue = 0;
        }

        console.log(`Property Upgraded Value: ${propertyUpgradeValue}`);
    }
    else if(screenName == "heatloss-calc-property-pipe"){
        propertyPipe = event.detail.fieldsSimple['heatloss-calc-property-pipe'];
        console.log(`Property Pipe: ${propertyPipe}`);

        switch (propertyPipe) {
        
            case "Yes":
                propertyPipeValue = 1.25;
                break;
        
            case "No":
                propertyPipeValue = 1;
                break;

              default:
                propertyPipeValue = 0;
        }

        console.log(`Property Pipe Value: ${propertyPipeValue}`);
    }
    
    console.log("");
    console.log("");
    
    let baseCost = 11500;
    console.log("Base Cost: "+baseCost);
    
    document.querySelector('[data-label="baseCost"]').value = "Base Cost: "+baseCost;

    let heatloss = (45 * propertyAgeValue) * propertyUpgradeValue;
    console.log("Initial Heatloss: "+heatloss);
    
    document.querySelector('[data-label="InitialHeatloss"]').value = "Initial Heatloss: "+heatloss;

    let totalFloorArea = ( 2 * propertyFloorAreaValue ) + 10;
    console.log("Total Floor Area: "+totalFloorArea);
    
    document.querySelector('[data-label="TotalFloorArea"]').value = "Total Floor Area: "+totalFloorArea;

    let FinalEstHeatlossW = ( heatloss *  totalFloorArea) *  propertyTypeValue;
    console.log("Final Est Heatloss in W per SQM: "+FinalEstHeatlossW);
    
    document.querySelector('[data-label="finalHeatlossW"]').value = "Final Est Heatloss in W per SQM: "+FinalEstHeatlossW.toLocaleString();

    let FinalEstHeatlosskW = FinalEstHeatlossW / 1000;
    console.log("Final Est Heatloss in kW per SQM: "+FinalEstHeatlosskW);
    
    document.querySelector('[data-label="finalHeatlosskW"]').value = FinalEstHeatlosskW.toLocaleString() + " kW";

    let categoryValue;

    if( FinalEstHeatlosskW >= 2 && FinalEstHeatlosskW <= 5 ){
        categoryValue = 0.8;
    }
    else if( FinalEstHeatlosskW > 5 && FinalEstHeatlosskW < 8 ){
        categoryValue = 0.9;
    }
    else if( FinalEstHeatlosskW >= 8 && FinalEstHeatlosskW < 12 ){
        categoryValue = 1;
    }
    else if( FinalEstHeatlosskW >= 12 && FinalEstHeatlosskW < 14 ){
        categoryValue = 1.3;
    }
    else if( FinalEstHeatlosskW >= 14 ){
        categoryValue = 1.5;
    }
    console.log("Category: "+categoryValue);
    
    document.querySelector('[data-label="category"]').value = "Category: "+categoryValue;

    let costPerRadiator = 180;
    console.log("Cost per Radiator: "+costPerRadiator);
    
    document.querySelector('[data-label="costPerRadiator"]').value = "Cost per Radiator: "+costPerRadiator;

    let radiatorCost = ( propertyBedroomsValue + 4 ) * costPerRadiator;
    console.log("Total Radiator Cost: "+radiatorCost);
    
    document.querySelector('[data-label="totalRadiatorCost"]').value = "Total Radiator Cost: "+radiatorCost;

    let totalCost = ( ( baseCost * categoryValue) * propertyPipeValue ) + radiatorCost;
    console.log("Final Total Cost: "+totalCost);
    
    document.querySelector('[data-label="finalCosting"]').value = "Final Total Cost: "+totalCost;
    
    console.log(totalCost);
    
    if(totalCost>22000){
        document.getElementById("text-block-id-bfe85ff7").style.display = "block";
        document.getElementById("text-block-id-402b983d").style.display = "none";
        
    }
    else{
        document.getElementById("text-block-id-bfe85ff7").style.display = "none";
        document.getElementById("text-block-id-402b983d").style.display = "block";
    }

    let finalCostFrom = totalCost * 0.93;
    let finalCostTo = totalCost * 1.03;

    // Format to two decimal points and GBP
    let formattedCostFrom = finalCostFrom.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP'
    });
    
    let formattedCostTo = finalCostTo.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP'
    });
    
    console.log(`Final Costing Range: ${formattedCostFrom} - ${formattedCostTo}`);
    
    document.querySelector('[data-label="finalCostingRange"]').value = `${formattedCostFrom} - ${formattedCostTo}`;

    if(totalCost>22000){
        console.log("Please contact a member of our team to discuss your enquiry.");
    }
    
    
});
